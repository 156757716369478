
import carriers from "@/data/carriers.json";
import Address from "@/components/Address";
var def = {
    regExps: [
        //eslint-disable-next-line
        /^(ups|endicia|deftship)$/i,
        //eslint-disable-next-line
        /dpd+/,
        //eslint-disable-next-line
        /dhl+/
    ],
    test: line => def.regExps.findIndex(a => a.test(line))
};

export default {
    components: {
        Address
    },
    emits: ["done", "update:visible"],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            // 国家数据
            carriers: carriers,
            // 表单数据
            form: Object.assign({}, this.data),
            // 表单验证规则
            rules: {
                name: [{
                    required: true,
                    message: this.$t("必填"),
                    type: "string",
                    trigger: "blur"
                }],
                api_class_type: [{
                    required: true,
                    message: this.$t("必填"),
                    type: "string",
                    trigger: "blur"
                }]
            },
            // 提交状态
            loading: false,
            // 是否是修改
            isUpdate: false,
            // API类型
            api_types: [],
            // 当前API配置项
            current_api_configs: [],
            // 当前API配置
            api_configs_value: {},
            /* 查询下拉选择 */
            api_types_search: "",
            /* 地址表单列表 */
            adress_form_arr: {},
            /* 激活折叠面板 */
            activeKey: ""
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
                this.form.enabled = this.form.enabled ? "1" : "0";
                this.api_configs_value = JSON.parse(this.form.config);
                this.form.api_class_type = this.form.api_class_type.replace("MoreLinkShip.Express", "shipapi.Libs.Express");
                this.isUpdate = true;
            } else {
                this.form = {
                    enabled: "1"
                };
                this.isUpdate = false;
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        },
        "form.api_class_type": {
            handler(value, oldValue) {
                var api_type = this.api_types.find(a => a.class_name === value);
                if (this.$refs.address_ref) {
                    this.$refs.address_ref.resetFields();
                }

                this.current_api_configs =
                    api_type != null ? api_type.configs : [];

                if (oldValue) {
                    var copy_value = {};
                    this.current_api_configs.forEach(
                        a =>
                        (copy_value[a.key] =
                            this.api_configs_value[a.key] || "")
                    );
                    this.api_configs_value = copy_value;
                }
            }
        }
    },
    computed: {
        /* 判断[adress]数量 */
        isAdress() {
            return this.current_api_configs.filter(item => {
                var regPass = item.name.toUpperCase().indexOf("[ADDRESS]") > -1;

                if (regPass) {
                    this.adress_form_arr[item.key] = Object.assign({
                        title: item.name,
                        require: item.require
                    }, JSON.parse(this.api_configs_value[item.key] || "{}"))
                }
                return regPass
            }).length > 0;
        },
        /* 过滤配置项 */
        render_current_api_configs() {
            let list = Array.from(this.current_api_configs);

            return list.filter(
                item => !(item.name.toUpperCase().indexOf("[ADDRESS]") > -1)
            );
        },
        /* 过滤类型下拉 */
        filter_api_types() {
            return this.api_types.filter(item => item.api_name.toLocaleLowerCase().indexOf(this.api_types_search.toLocaleLowerCase()) > -1)
        }
    },
    mounted() {
        this.load_api_types();
    },
    methods: {
        // 获取资源
        getLogoAssets(row) {
            try {
                row.logo = require(`@/assets/api_logo/${row.logo}`);
            } catch (e) {
                row.logo = require(`@/assets/api_logo/404.png`);
            }
            return row.logo
        },
        load_api_types() {
            this.$http
                .get("/admin/ship_method_api/api_types")
                .then(res => {
                    if (res.data.code === 0) {
                        this.api_types = res.data.data.map((item) => {
                            item.logo = process.env.VUE_APP_API_LOGO_URL + item.logo
                            return item
                        });
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    this.$message.error(e.message);
                });
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit("update:visible", value);
        },
        /* 保存编辑 */
        save() {
            let promise_arr = [];
            if (this.isAdress) {
                for (let key in this.adress_form_arr) {
                    promise_arr.push(this.$refs[`address_ref_${key}`].validate());
                    delete this.adress_form_arr[key].title;
                    delete this.adress_form_arr[key].require;
                    if (JSON.stringify(this.adress_form_arr[key]) === '{}') {
                        delete this.api_configs_value[key];
                    } else {
                        this.api_configs_value[key] = JSON.stringify(this.adress_form_arr[key])
                    }
                }
            }
            Promise.all(promise_arr, this.$refs.form.validate())
                .then(() => {
                    this.loading = true;

                    var body = Object.assign({}, this.form, {
                        enabled: this.form.enabled == "1",
                        config: JSON.stringify(this.api_configs_value)
                    });

                    this.$http
                        .post("/admin/ship_method_api/update", body)
                        .then(res => {
                            this.loading = false;
                            if (res.data.code === 0) {
                                this.$message.success(res.data.msg);
                                if (!this.isUpdate) {
                                    this.form = {};
                                }
                                this.updateVisible(false);
                                this.$emit("done");
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                })
                .catch(() => {});
        },
        onClose() {
            this.updateVisible(false);
        },
        /* 处理查询下拉 */
        handleSearch(val) {
            this.api_types_search = val;
        },
        /* 清空数据 */
        handleClearConfig(event, key, row) {
            event.stopPropagation();
            this.adress_form_arr[key] = {
                title: row.title,
                require: row.require
            };
        }
    }
};
